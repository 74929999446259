<template>
  <vue-command
    :commands="commands"
    :help-timeout="1500"
    :history.sync="history"
    :invert="false"
    :title="title"
    help-text="Enter help to get a list of available commands"
    hide-buttons
    prompt="julian@berlin~$"
    show-help
  />
</template>

<script>
import VueCommand, {
  createQuery,
  createStdout,
  listFormatter,
  newDefaultHistory,
  tableFormatter,
  textFormatter,
} from "vue-command";
import "vue-command/dist/vue-command.css";

export default {
  components: {
    VueCommand,
  },

  data() {
    return {
      commands: {
        clear: () => {
          // "splice" is necessary since Vue.js losses its reactivity if array is
          // set to empty
          this.history.splice(0, this.history.length);

          return createQuery();
        },

        clients: () =>
          createStdout(
            tableFormatter([
              [
                "Mercedes-Benz Tech Innovation",
                '"Mercedes-Benz Tech Innovation develops exclusive and innovative IT solutions for Mercedes-Benz Group AG and tomorrow\'s mobility"',
              ],
              [
                "EVANA",
                '"Get your AI powered data rooms up and running in 10 minutes"',
              ],
              [
                "Blue Canvas",
                '"Blue Canvas provides Git-based source control, CI and DevOps solutions for Salesforce developers and admins"',
              ],
              [
                "Fanomena",
                '"We empower companies to deliver the right content, to the right person, at the right time and significantly increase their relevance and conversion rates"',
              ],
              ["Interlutions", '"Your Experts for Digital Transformation"'],
            ])
          ),

        links: () =>
          createStdout(
            textFormatter(
              `<a href="https://github.com/ndabAP" target="_blank">Github</a>
            <a href="https://www.reddit.com/user/ENx5vP" target="_blank">Reddit</a>
            <a href="https://www.youtube.com/@julianclaus7045" target="_blank">YouTube</a>
      `,
              true
            )
          ),

        help: () =>
          createStdout(`clear
      clients
      help
      hobbies
      links
      skills
      `),

        hobbies: () =>
          createStdout(
            listFormatter("coding", "gaming", "spending time with my family")
          ),

        skills: () =>
          createStdout(
            tableFormatter([
              ["Go", "Vue.js", "JavaScript", "Node.js", "TypeScript"],
              ["MongoDB", "MySQL", "Redis"],
              [
                "Express.js",
                "Capacitor/Ionic",
                "GraphQL",
                "Vuetify",
                "Firebase",
              ],
              ["Github", "Google Cloud", "Azure", "Gitlab", "MongoDB Atlas"],
            ])
          ),
      },

      history: newDefaultHistory(),
    };
  },

  computed: {
    title: {
      get() {
        const width = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );
        const height = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );
        return `Terminal - ${width}x${height}`;
      },
    },
  },
};
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
}

a {
  color: #f1f1f1;
}

#app {
  padding: 0;
  margin: 0;
}

.vue-command--invert, .vue-command {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  height: 100vh;

  .vue-command__bar__title--invert, .vue-command__bar__title {
    font-size: 1rem;
    font-family: monospace;
  }

  .vue-command__query input,
  .vue-command__query input::placeholder {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 1rem;
  }
}
</style>
